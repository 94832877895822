import axios from 'axios';

const BASE_URL = 'https://api.isendxpress.com/api/auth'; 

const AuthService = {
  login: async (phone, password) => {
    try {
      const response = await axios.post(`${BASE_URL}/login`, {
        phone,
        password,
      });

      // Store the token and user information in localStorage or sessionStorage
      const { access_token, user } = response.data.data;
      console.log(response.data);
      localStorage.setItem('auth_token', access_token);
      localStorage.setItem('user', JSON.stringify(user));

      return response.data;
    } catch (error) {
      throw error.response ? error.response.data : error.message;
    }
  },

  logout: () => {
    // Remove the token and user information from localStorage or sessionStorage
    localStorage.removeItem('auth_token');
    localStorage.removeItem('user');
  },

  getToken: () => {
    return localStorage.getItem('auth_token');
  },

  isAuthenticated: () => {
    return !!localStorage.getItem('auth_token');
  },

  getUser: () => {
    const user = localStorage.getItem('user');
    return user ? JSON.parse(user) : null;
  },
};

export default AuthService;
