import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Sidebar from '../components/Sidebar';
import SettingsSection from '../components/SettingsSection';
import ProfileSettings from '../components/ProfileSettings';
import AccountSettings from '../components/AccountSettings';
import NotificationSettings from '../components/NotificationSettings';
import PrivacySettings from '../components/PrivacySettings';
import LanguageSettings from '../components/LanguageSetting';

const Settings = () => {
  return (
    <div className="flex bg-gray-100 min-h-screen">
      <Sidebar isOpen={true} toggleSidebar={() => {}} />
      <div className="flex-1 flex flex-col ml-64">
        <Header />
        <main className="p-6 flex-1 bg-white shadow-lg rounded-lg mt-6 mx-4">
          <h1 className="text-3xl font-semibold text-gray-800 mb-6">Settings</h1>

          {/* Settings Sections */}
          <div className="space-y-8">
            <SettingsSection
              title="Profile Settings"
              description="Manage your personal details"
              content={<ProfileSettings />}
            />
            <SettingsSection
              title="Account Settings"
              description="Update your email, password, and more"
              content={<AccountSettings />}
            />
            <SettingsSection
              title="Notification Settings"
              description="Control your notification preferences"
              content={<NotificationSettings />}
            />
            <SettingsSection
              title="Privacy Settings"
              description="Set your privacy preferences"
              content={<PrivacySettings />}
            />
            <SettingsSection
              title="Language and Preferences"
              description="Choose your language and update other preferences"
              content={<LanguageSettings />}
            />
          </div>
        </main>
        <Footer />
      </div>
    </div>
  );
};

export default Settings;
