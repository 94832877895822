import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers, faTasks, faDollarSign, faClock } from '@fortawesome/free-solid-svg-icons';

const icons = {
  users: faUsers,
  tasks: faTasks,
  'dollar-sign': faDollarSign,
  clock: faClock,
};

const DashboardWidgets = ({ title, count, icon, bgColor }) => {
  return (
    <div className={`flex items-center justify-between p-4 rounded-lg shadow-lg text-white ${bgColor}`}>
      <div>
        <h3 className="text-lg font-semibold">{title}</h3>
        <p className="text-2xl font-bold">{count}</p>
      </div>
      <FontAwesomeIcon icon={icons[icon]} className="text-4xl" />
    </div>
  );
};

export default DashboardWidgets;
