import React, { useState } from 'react';
import { FiSearch, FiFilter } from 'react-icons/fi';

const ContactsList = () => {
  const [contacts] = useState([
    { id: 1, name: 'John Doe', email: 'john@example.com', message: 'Can I get more information about the platform?' },
    { id: 2, name: 'Jane Smith', email: 'jane@example.com', message: 'How do I reset my password?' },
    { id: 3, name: 'Alice Johnson', email: 'alice@example.com', message: 'I need help with the course material.' },
    { id: 4, name: 'Bob Brown', email: 'bob@example.com', message: 'Are there any discounts available?' },
  ]);

  const [searchQuery, setSearchQuery] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleStatusFilter = (e) => {
    setSelectedStatus(e.target.value);
  };

  const filteredContacts = contacts
    .filter(contact =>
      contact.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      contact.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
      contact.message.toLowerCase().includes(searchQuery.toLowerCase())
    )
    .filter(contact => {
      if (selectedStatus) {
        return contact.message.includes(selectedStatus); // Just an example, modify according to your needs
      }
      return true;
    });

  return (
    <div className="space-y-6">
      {/* Search and Filter Section */}
      <div className="flex items-center space-x-4 mb-6">
        <div className="relative w-full md:w-1/3">
          <input
            type="text"
            className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="Search Contacts"
            value={searchQuery}
            onChange={handleSearch}
          />
          <FiSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500" size={18} />
        </div>

        <div className="relative w-full md:w-1/4">
          <select
            className="w-full py-2 pl-3 pr-10 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            value={selectedStatus}
            onChange={handleStatusFilter}
          >
            <option value="">All Statuses</option>
            <option value="info">Request for more info</option>
            <option value="reset">Password reset request</option>
            <option value="help">Need help with course</option>
            <option value="discount">Inquire about discounts</option>
          </select>
          <FiFilter className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500" size={18} />
        </div>
      </div>

      {/* Contact List Table */}
      <div className="overflow-x-auto shadow-lg rounded-lg bg-white">
        <table className="w-full text-left table-auto">
          <thead className="bg-gray-200">
            <tr>
              <th className="py-3 px-4 text-gray-600 font-semibold">Name</th>
              <th className="py-3 px-4 text-gray-600 font-semibold">Email</th>
              <th className="py-3 px-4 text-gray-600 font-semibold">Message</th>
              <th className="py-3 px-4 text-gray-600 font-semibold">Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredContacts.map(contact => (
              <tr key={contact.id} className="hover:bg-gray-100">
                <td className="py-3 px-4 text-gray-700">{contact.name}</td>
                <td className="py-3 px-4 text-gray-700">{contact.email}</td>
                <td className="py-3 px-4 text-gray-700">{contact.message}</td>
                <td className="py-3 px-4 text-gray-700">
                  <button className="text-blue-500 hover:text-blue-700">View</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ContactsList;
