const ServerStatus = () => {
    const status = "Online"; 
    const serverLoad = "12%"; 
  
    return (
      <div className="bg-white shadow-lg rounded-lg p-6" data-aos="fade-right">
        <h2 className="text-xl font-semibold text-gray-700 mb-4">Server Status</h2>
        <div className="flex justify-between items-center">
          <div className="flex items-center space-x-3">
            <div
              className={`w-4 h-4 rounded-full ${status === "Online" ? 'bg-green-500' : 'bg-red-500'}`}
            />
            <span className="text-lg">{status}</span>
          </div>
          <span className="text-gray-500">Load: {serverLoad}</span>
        </div>
      </div>
    );
  };
  
  export default ServerStatus;
  