import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Sidebar from '../components/Sidebar';
import ContactsList from '../components/ContactList';

const Contacts = () => {
  return (
    <div className="flex bg-gray-100 min-h-screen">
      <Sidebar isOpen={true} toggleSidebar={() => {}} />
      <div className="flex-1 flex flex-col ml-64">
        <Header />
        <main className="p-6 flex-1 bg-white shadow-lg rounded-lg mt-6 mx-4">
          <h1 className="text-3xl font-semibold text-gray-800 mb-6">Customer Contacts</h1>
          <ContactsList />
        </main>
        <Footer />
      </div>
    </div>
  );
};

export default Contacts;
