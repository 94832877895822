const ActivityLog = () => {
    const activities = [
      { id: 1, action: "User John Doe updated profile", time: "2 mins ago" },
      { id: 2, action: "New request created by Alice", time: "10 mins ago" },
      { id: 3, action: "System health check completed", time: "20 mins ago" },
    ];
  
    return (
      <div className="bg-white shadow-lg rounded-lg p-6" data-aos="fade-up">
        <h2 className="text-xl font-semibold text-gray-700 mb-4">Activity Log</h2>
        <ul className="space-y-3">
          {activities.map((activity) => (
            <li key={activity.id} className="flex justify-between text-gray-600">
              <span>{activity.action}</span>
              <span className="text-sm text-gray-400">{activity.time}</span>
            </li>
          ))}
        </ul>
      </div>
    );
  };
  
  export default ActivityLog;
  