import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import requestService from '../services/requestService';

const RecentRequests = () => {
  const [requests, setRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const getRequests = async () => {
    try {
      const data = await requestService.getAllRequests();
      setRequests(data.data.pickupRequests.data);
      setError(null);
    } catch (err) {
      console.error('Error fetching recent requests:', err);
      setError(err.response ? err.response.data.message : 'Failed to load recent requests.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getRequests();
  }, []);

  const handleViewRequest = (id) => {
    navigate(`/requests/${id}`);
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'pending':
        return 'bg-yellow-200 text-yellow-800';
      case 'completed':
        return 'bg-green-200 text-green-800';
      case 'in_progress':
        return 'bg-blue-200 text-blue-800';
      case 'cancelled':
        return 'bg-red-200 text-red-800';
      default:
        return 'bg-gray-200 text-gray-800';
    }
  };

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    return new Intl.DateTimeFormat('en-US', options).format(new Date(dateString));
  };

  if (loading) {
    return (
      <div className="bg-white shadow-lg rounded-lg p-6 flex items-center justify-center">
        <h2 className="text-xl font-semibold text-gray-700 mb-4">Recent Requests</h2>
        <div className="flex justify-center items-center">
          <div className="animate-spin rounded-full h-6 w-6 border-t-2 border-b-2 border-blue-500"></div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-white shadow-lg rounded-lg p-6">
        <h2 className="text-xl font-semibold text-gray-700 mb-4">Recent Requests</h2>
        <p className="text-red-600">{error}</p>
      </div>
    );
  }

  return (
    <div className="bg-white shadow-lg rounded-lg p-6">
      <h2 className="text-xl font-semibold text-gray-700 mb-4">Recent Requests</h2>
      <table className="table-auto w-full border-collapse border border-gray-200">
        <thead>
          <tr className="bg-gray-100">
            <th className="border border-gray-200 px-4 py-2 text-left">Item Name</th>
            <th className="border border-gray-200 px-4 py-2 text-left">Sender</th>
            <th className="border border-gray-200 px-4 py-2 text-left">Recepient</th>
            <th className="border border-gray-200 px-4 py-2 text-left">Fron</th>
            <th className="border border-gray-200 px-4 py-2 text-left">To</th>
            <th className="border border-gray-200 px-4 py-2 text-left">Status</th>
            <th className="border border-gray-200 px-4 py-2 text-left">Amount</th>
          </tr>
        </thead>
        <tbody>
          {requests.map((request, index) => (
            <tr
              key={request.id}
              className={`${index % 2 === 0 ? 'bg-white' : 'bg-gray-50'} hover:bg-gray-100`}
            >
              <td className="border border-gray-200 px-4 py-2">{request.item_name}</td>
              <td className="border border-gray-200 px-4 py-2">{request.sender_name}</td>
              <td className="border border-gray-200 px-4 py-2">{request.recipient_name}</td>
              <td className="border border-gray-200 px-4 py-2">{request.from_address}</td>
              <td className="border border-gray-200 px-4 py-2">{request.to_address}</td>
              <td className="border border-gray-200 px-4 py-2">
                <span
                  className={`inline-block px-3 py-1 rounded-full text-xs font-semibold ${getStatusColor(
                    request.delivery_status
                  )}`}
                >
                  {request.delivery_status}
                </span>
              </td>
              <td className="border border-gray-200 px-4 py-2 text-right">
                XAF {request.cost}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default RecentRequests;
