const ProfileSettings = () => {
    return (
      <div className="space-y-6">
        <div className="flex items-center space-x-6">
          <div className="w-1/3">
            <label className="block text-gray-600 mb-2">Name</label>
            <input
              type="text"
              className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="John Doe"
            />
          </div>
          <div className="w-1/3">
            <label className="block text-gray-600 mb-2">Email</label>
            <input
              type="email"
              className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="john@example.com"
            />
          </div>
        </div>
  
        <div className="flex items-center space-x-6">
          <div className="w-1/3">
            <label className="block text-gray-600 mb-2">Phone Number</label>
            <input
              type="text"
              className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="+1 234 567 890"
            />
          </div>
        </div>
  
        <div className="text-right mt-4">
          <button className="px-6 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700">Save Changes</button>
        </div>
      </div>
    );
  };
  
  export default ProfileSettings;
  