import axios from 'axios';

const apiClient = axios.create({
  baseURL: 'https://api.isendxpress.com/api',
  headers: {
    'Content-Type': 'application/json',
  },
});

const getAuthToken = () => {
  return localStorage.getItem('auth_token');
};

const categoryService = {
  getCategories: async () => {
    try {
      const authToken = getAuthToken();
      const response = await apiClient.get('/categories', {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching categories:', error);
      throw error;
    }
  },

  getCategoryById: async (id) => {
    try {
      const authToken = getAuthToken();
      const response = await apiClient.get(`/categories/${id}`, {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      return response.data;
    } catch (error) {
      console.error(`Error fetching category with id ${id}:`, error);
      throw error;
    }
  },

  addCategory: async (categoryData) => {
    try {
      const authToken = getAuthToken();
      const response = await apiClient.post('/categories', categoryData, {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      return response.data;
    } catch (error) {
      console.error('Error adding category:', error);
      throw error;
    }
  },

  updateCategory: async (id, categoryData) => {
    try {
      const authToken = getAuthToken();
      const response = await apiClient.put(`/categories/${id}`, categoryData, {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      return response.data;
    } catch (error) {
      console.error(`Error updating category with id ${id}:`, error);
      throw error;
    }
  },

  deleteCategory: async (id) => {
    try {
      const authToken = getAuthToken();
      const response = await apiClient.delete(`/categories/${id}`, {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      return response.data;
    } catch (error) {
      console.error(`Error deleting category with id ${id}:`, error);
      throw error;
    }
  },
};

export default categoryService;
