import axios from 'axios';

const BASE_URL = 'https://api.isendxpress.com/api/pickup-requests'; // Replace with your API base URL

// Helper function to get token from localStorage
const getAuthToken = () => {
  const token = localStorage.getItem('auth_token');  
  console.log(token)
  return token ? `Bearer ${token}` : '';  
  };


const requestService = {
  getAllRequests: async (filters = {}) => {
    try {
      const response = await axios.get(BASE_URL, {
        params: filters,
        headers: { Authorization: getAuthToken() },  // Add token to headers
      });
      return response.data;
    } catch (error) {
      throw error.response ? error.response.data : error.message;
    }
  },

  getRequestById: async (id) => {
    try {
      const response = await axios.get(`${BASE_URL}/${id}`, {
        headers: { Authorization: getAuthToken() },  // Add token to headers
      });
      return response.data;
    } catch (error) {
      throw error.response ? error.response.data : error.message;
    }
  },

  createRequest: async (data) => {
    try {
      const response = await axios.post(BASE_URL, data, {
        headers: { 
          'Content-Type': 'multipart/form-data',
          Authorization: getAuthToken(),         
        },
      });
      return response.data;
    } catch (error) {
      throw error.response ? error.response.data : error.message;
    }
  },

  updateRequest: async (id, data) => {
    try {
      const response = await axios.put(`${BASE_URL}/${id}`, data, {
        headers: { Authorization: getAuthToken() },  // Add token to headers
      });
      return response.data;
    } catch (error) {
      throw error.response ? error.response.data : error.message;
    }
  },

  deleteRequest: async (id) => {
    try {
      const response = await axios.delete(`${BASE_URL}/${id}`, {
        headers: { Authorization: getAuthToken() },  // Add token to headers
      });
      return response.data;
    } catch (error) {
      throw error.response ? error.response.data : error.message;
    }
  },

  getByDeliveryCode: async (deliveryCode) => {
    try {
      const response = await axios.get(`${BASE_URL}/delivery/status`, {
        params: { delivery_code: deliveryCode },
        headers: { Authorization: getAuthToken() },  // Add token to headers
      });
      return response.data;
    } catch (error) {
      throw error.response ? error.response.data : error.message;
    }
  },

  getUserRequests: async (userId) => {
    try {
      const response = await axios.get(`${BASE_URL}/user`, {
        params: { user_id: userId },
        headers: { Authorization: getAuthToken() },  // Add token to headers
      });
      return response.data;
    } catch (error) {
      throw error.response ? error.response.data : error.message;
    }
  },

  updateStatus: async (requestId, statusData) => {
    try {
      const response = await axios.put(
        `${BASE_URL}/${requestId}/status`,
        statusData,
        {
          headers: { Authorization: getAuthToken() },
        }
      );
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data?.message || error.message);
    }
  },
  
};


export default requestService;
