import React from 'react';
import { Link } from 'react-router-dom'; // Import Link for routing
import { FiHome, FiUsers, FiSettings, FiFileText, FiMenu, FiShoppingCart, FiPackage, FiClipboard, FiMessageSquare } from 'react-icons/fi';

const Sidebar = ({ isOpen, toggleSidebar }) => {
  return (
    <aside
      className={`fixed inset-y-0 left-0 transform ${isOpen ? 'translate-x-0' : '-translate-x-full'}
        bg-blue-900 text-white p-4 w-64 transition-transform duration-300 ease-in-out shadow-lg`}
    >
      <div className="flex items-center justify-between p-4">
        <h2 className={`text-2xl font-bold transition-opacity duration-300 ${isOpen ? 'opacity-100' : 'opacity-0'}`}>
          Admin Panel
        </h2>
        <button onClick={toggleSidebar} className="text-white focus:outline-none">
          <FiMenu size={24} />
        </button>
      </div>

      <div className={`my-4 px-4 transition-opacity duration-300 ${isOpen ? 'opacity-100' : 'opacity-0 hidden'}`}>
        <input
          type="text"
          placeholder="Search..."
          className="w-full p-2 bg-blue-800 text-white rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
        />
      </div>

      <nav>
        <ul className="space-y-2">
          <li>
            <Link
              to="/dashboard"
              className="flex items-center py-3 px-4 hover:bg-blue-800 rounded-md transition-all duration-200 group"
            >
              <FiHome size={20} className="mr-4 group-hover:text-blue-400" />
              <span className={`${isOpen ? 'opacity-100' : 'opacity-0 hidden'} transition-opacity duration-300`}>
                Dashboard
              </span>
            </Link>
          </li>
          <li>
            <Link
              to="/users"
              className="flex items-center py-3 px-4 hover:bg-blue-800 rounded-md transition-all duration-200 group"
            >
              <FiUsers size={20} className="mr-4 group-hover:text-blue-400" />
              <span className={`${isOpen ? 'opacity-100' : 'opacity-0 hidden'} transition-opacity duration-300`}>
                Users
              </span>
            </Link>
          </li>
          <li>
            <Link
              to="/transactions"
              className="flex items-center py-3 px-4 hover:bg-blue-800 rounded-md transition-all duration-200 group"
            >
              <FiShoppingCart size={20} className="mr-4 group-hover:text-blue-400" />
              <span className={`${isOpen ? 'opacity-100' : 'opacity-0 hidden'} transition-opacity duration-300`}>
                Transactions
              </span>
            </Link>
          </li>
          <li>
            <Link
              to="/pickup-requests"
              className="flex items-center py-3 px-4 hover:bg-blue-800 rounded-md transition-all duration-200 group"
            >
              <FiPackage size={20} className="mr-4 group-hover:text-blue-400" />
              <span className={`${isOpen ? 'opacity-100' : 'opacity-0 hidden'} transition-opacity duration-300`}>
                Pickup Requests
              </span>
            </Link>
          </li>
          <li>
            <Link
              to="/reports"
              className="flex items-center py-3 px-4 hover:bg-blue-800 rounded-md transition-all duration-200 group"
            >
              <FiClipboard size={20} className="mr-4 group-hover:text-blue-400" />
              <span className={`${isOpen ? 'opacity-100' : 'opacity-0 hidden'} transition-opacity duration-300`}>
                Reports
              </span>
            </Link>
          </li>
          <li>
            <Link
              to="/settings"
              className="flex items-center py-3 px-4 hover:bg-blue-800 rounded-md transition-all duration-200 group"
            >
              <FiSettings size={20} className="mr-4 group-hover:text-blue-400" />
              <span className={`${isOpen ? 'opacity-100' : 'opacity-0 hidden'} transition-opacity duration-300`}>
                Settings
              </span>
            </Link>
          </li>
          <li>
            <Link
              to="/invoices"
              className="flex items-center py-3 px-4 hover:bg-blue-800 rounded-md transition-all duration-200 group"
            >
              <FiFileText size={20} className="mr-4 group-hover:text-blue-400" />
              <span className={`${isOpen ? 'opacity-100' : 'opacity-0 hidden'} transition-opacity duration-300`}>
                Invoices
              </span>
            </Link>
          </li>
          <li>
            <Link
              to="/support"
              className="flex items-center py-3 px-4 hover:bg-blue-800 rounded-md transition-all duration-200 group"
            >
              <FiUsers size={20} className="mr-4 group-hover:text-blue-400" />
              <span className={`${isOpen ? 'opacity-100' : 'opacity-0 hidden'} transition-opacity duration-300`}>
                Customer Support
              </span>
            </Link>
          </li>
          {/* New Contacts Tab */}
          <li>
            <Link
              to="/contacts"
              className="flex items-center py-3 px-4 hover:bg-blue-800 rounded-md transition-all duration-200 group"
            >
              <FiMessageSquare size={20} className="mr-4 group-hover:text-blue-400" />
              <span className={`${isOpen ? 'opacity-100' : 'opacity-0 hidden'} transition-opacity duration-300`}>
                Contacts
              </span>
            </Link>
          </li>
        </ul>
      </nav>
    </aside>
  );
};

export default Sidebar;
