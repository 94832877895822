import React from 'react';
import { CgCopy } from 'react-icons/cg';

const DeliveryCodeField = ({ delivery_code }) => {
    const copyToClipboard = () => {
        navigator.clipboard.writeText(delivery_code);
        alert('Delivery code copied to clipboard!'); // Optional: Display a confirmation message
    };

    return (
        <div className="p-4 rounded-lg shadow-md bg-white">
            <p className="font-medium text-gray-800">Delivery Code</p>
            <div className="flex items-center justify-between border border-gray-300 p-3 rounded-lg mt-2 bg-gray-50">
                <span className="text-lg text-gray-900">{delivery_code}</span>
                <button 
                    onClick={copyToClipboard} 
                    className="ml-2 p-1 rounded hover:bg-gray-200 focus:outline-none"
                    aria-label="Copy delivery code"
                >
                    <CgCopy className="h-5 w-5 text-gray-600" />
                </button>
            </div>
        </div>
    );
};

export default DeliveryCodeField;