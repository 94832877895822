import React, { useState } from 'react';
import { FiSearch, FiEdit, FiTrash2, FiEye } from 'react-icons/fi';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import Footer from '../components/Footer';

const Users = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedRole, setSelectedRole] = useState('');
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleRoleFilter = (e) => {
    setSelectedRole(e.target.value);
  };

  const users = [
    { id: 1, name: 'John Doe', email: 'john@example.com', role: 'Admin' },
    { id: 2, name: 'Jane Smith', email: 'jane@example.com', role: 'User' },
    { id: 3, name: 'Alice Johnson', email: 'alice@example.com', role: 'Tutor' },
    { id: 4, name: 'Bob Brown', email: 'bob@example.com', role: 'Admin' },
  ];

  const filteredUsers = users
    .filter(user =>
      user.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      user.email.toLowerCase().includes(searchQuery.toLowerCase())
    )
    .filter(user => {
      if (selectedRole) {
        return user.role === selectedRole;
      }
      return true;
    });

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className="flex flex-col min-h-screen bg-gray-100">
      <div className="flex flex-1">
        <Sidebar isOpen={isSidebarOpen} />
        <div className={`flex-1 ${isSidebarOpen ? 'pl-64' : 'pl-16'} transition-all`}>
          <Header toggleSidebar={toggleSidebar} isSidebarOpen={isSidebarOpen} />

          <main className="p-6">
            <h1 className="text-3xl font-semibold text-gray-700 mb-6">Users</h1>

            <div className="flex items-center mb-6 space-x-4">
              <div className="relative w-full md:w-1/3">
                <input
                  type="text"
                  className="w-full pl-10 pr-4 py-2 border border-blue-400 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 bg-white"
                  placeholder="Search Users"
                  value={searchQuery}
                  onChange={handleSearch}
                />
                <FiSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500" size={18} />
              </div>

              <div className="relative w-full md:w-1/4">
                <select
                  className="w-full py-2 pl-3 pr-10 border border-blue-400 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 bg-white"
                  value={selectedRole}
                  onChange={handleRoleFilter}
                >
                  <option value="">All Roles</option>
                  <option value="Admin">Admin</option>
                  <option value="User">User</option>
                  <option value="Tutor">Tutor</option>
                </select>
              </div>
            </div>

            <div className="overflow-x-auto bg-white shadow-lg rounded-lg">
              <table className="w-full table-auto">
                <thead className="bg-blue-50 text-blue-600">
                  <tr>
                    <th className="py-3 px-6 text-left">Name</th>
                    <th className="py-3 px-6 text-left">Email</th>
                    <th className="py-3 px-6 text-left">Role</th>
                    <th className="py-3 px-6 text-center">Actions</th>
                  </tr>
                </thead>
                <tbody className="text-gray-700">
                  {filteredUsers.map((user, index) => (
                    <tr
                      key={user.id}
                      className={`border-t ${index % 2 === 0 ? 'bg-gray-50' : 'bg-white'}`}
                    >
                      <td className="py-3 px-6">{user.name}</td>
                      <td className="py-3 px-6">{user.email}</td>
                      <td className="py-3 px-6">{user.role}</td>
                      <td className="py-3 px-6 text-center">
                        <button className="text-blue-500 hover:text-blue-700 mr-2">
                          <FiEye size={18} />
                        </button>
                        <button className="text-yellow-500 hover:text-yellow-700 mr-2">
                          <FiEdit size={18} />
                        </button>
                        <button className="text-red-500 hover:text-red-700">
                          <FiTrash2 size={18} />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </main>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Users;
