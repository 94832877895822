import React, { useState } from 'react';
import { useNavigate, useLocation, Navigate } from 'react-router-dom';
import AuthService from '../services/AuthService';


const Login = () => {
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const message = location.state?.message || '';

  if (AuthService.isAuthenticated()) {
    return <Navigate to="/dashboard" replace />;
  }

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await AuthService.login(phone, password);
      if (response) {
        setError('');
        navigate('/dashboard');
      }
    } catch (err) {
      setError(err.message || 'Invalid Phone or password.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gradient-to-r from-blue-900 to-blue-700">
      <div className="bg-white rounded-lg shadow-lg p-8 w-full max-w-md">
        <h1 className="text-2xl font-bold text-center text-gray-800 mb-6">Admin Login</h1>

        {message && (
          <div className="mb-4 text-sm text-blue-600 border border-blue-600 rounded-lg p-2 text-center">
            {message}
          </div>
        )}

        {error && (
          <div className="mb-4 text-sm text-red-600 border border-red-600 rounded-lg p-2 text-center">
            {error}
          </div>
        )}

        <form onSubmit={handleLogin} className="space-y-4">
          <div>
            <label htmlFor="phone" className="block text-gray-700 font-medium mb-1">Phone</label>
            <input
              type="text"
              id="phone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              placeholder="681610898"
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>

          <div>
            <label htmlFor="password" className="block text-gray-700 font-medium mb-1">Password</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Enter your password"
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>

          <button
            type="submit"
            className="w-full bg-blue-600 text-white py-2 rounded-lg hover:bg-blue-700 transition duration-200 font-semibold"
            disabled={loading}
          >
            {loading ? <span>Loading...</span> : 'Login'}
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;
