const LanguageSettings = () => {
    return (
      <div className="space-y-6">
        <div className="w-full">
          <label className="block text-gray-600 mb-2">Language</label>
          <select className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500">
            <option value="en">English</option>
            <option value="fr">French</option>
            <option value="es">Spanish</option>
            {/* Add more languages here */}
          </select>
        </div>
  
        <div className="text-right mt-4">
          <button className="px-6 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700">Save Changes</button>
        </div>
      </div>
    );
  };
  
  export default LanguageSettings;
  