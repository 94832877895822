const SettingsSection = ({ title, description, content }) => {
    return (
      <div className="bg-white p-6 shadow-lg rounded-lg">
        <h2 className="text-2xl font-semibold text-gray-800">{title}</h2>
        <p className="text-gray-600 mb-4">{description}</p>
        {content}
      </div>
    );
  };
  
  export default SettingsSection;
  