import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => {
  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="text-center p-6 bg-white shadow-lg rounded-lg">
        <h1 className="text-6xl font-bold text-red-600 mb-4">404</h1>
        <p className="text-xl text-gray-700 mb-4">Oops! The page you are looking for does not exist.</p>
        <Link to="/dashboard" className="text-blue-600 hover:text-blue-800 text-lg font-semibold">
          Go back to Dashboard
        </Link>
      </div>
    </div>
  );
};

export default NotFound;
