import React, { useState, useEffect } from 'react';
import { FiMenu, FiUser, FiLogOut, FiSettings } from 'react-icons/fi';

const Header = ({ toggleSidebar, isSidebarOpen }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [userName, setUserName] = useState('');
  const [userAvatar, setUserAvatar] = useState('');

  useEffect(() => {
    // Fetch the user data from localStorage if it's set during login
    const storedUser = localStorage.getItem('user');

    console.log(storedUser)
    
    if (storedUser) {
      const { name, userAvatar } = JSON.parse(storedUser);  // Parse the stored data
      setUserAvatar('https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y');
      setUserName(name);
    } else {
      // Fallback data if no user found in localStorage
      setUserName('Guest');
      setUserAvatar('https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y');
    }
  }, []);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };

  return (
    <header className="bg-white shadow-md p-4 flex items-center justify-between">
      {/* Menu Toggle */}
      <button onClick={toggleSidebar} className="text-gray-700 focus:outline-none">
        <FiMenu size={24} />
      </button>

      {/* Title and Welcome Message */}
      <div className="flex items-center space-x-4">
        <h1 className="text-xl font-semibold text-gray-700">Admin Panel</h1>
        <span className="text-md text-gray-500">Welcome, {userName}</span>
      </div>

      {/* Profile Dropdown */}
      <div className="relative">
        <button
          onClick={toggleDropdown}
          className="flex items-center space-x-2 text-gray-700 focus:outline-none"
        >
          <img
            src={userAvatar}  // Use the avatar from localStorage
            alt="User Avatar"
            className="w-8 h-8 rounded-full"
          />
          <span className="hidden md:block">{userName}</span>
        </button>
        
        {/* Dropdown Menu */}
        {isDropdownOpen && (
          <div className="absolute right-0 mt-2 w-48 bg-white border border-gray-200 rounded-md shadow-lg">
            <ul>
              <li>
                <a
                  href="#"
                  className="flex items-center px-4 py-2 text-gray-700 hover:bg-gray-100"
                  onClick={closeDropdown}
                >
                  <FiUser size={18} className="mr-2" />
                  <span className="text-sm">Profile</span>
                </a>
              </li>
              <li>
                <a
                  href="#"
                  className="flex items-center px-4 py-2 text-gray-700 hover:bg-gray-100"
                  onClick={closeDropdown}
                >
                  <FiSettings size={18} className="mr-2" />
                  <span className="text-sm">Settings</span>
                </a>
              </li>
              <li>
                <a
                  href="#"
                  className="flex items-center px-4 py-2 text-gray-700 hover:bg-gray-100"
                  onClick={closeDropdown}
                >
                  <FiLogOut size={18} className="mr-2" />
                  <span className="text-sm">Logout</span>
                </a>
              </li>
            </ul>
          </div>
        )}
      </div>
    </header>
  );
};

export default Header;
