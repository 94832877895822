import { Line } from 'react-chartjs-2';

const UserGrowthChart = () => {
  const data = {
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
    datasets: [
      {
        label: 'New Users',
        data: [30, 50, 70, 90, 110, 130],
        fill: false,
        borderColor: 'rgb(75, 192, 192)',
        tension: 0.1,
      },
    ],
  };

  return (
    <div className="bg-white shadow-lg rounded-lg p-6" data-aos="fade-left">
      <h2 className="text-xl font-semibold text-gray-700 mb-4">User Growth Over Time</h2>
      <Line data={data} />
    </div>
  );
};

export default UserGrowthChart;
