const AccountSettings = () => {
    return (
      <div className="space-y-6">
        <div className="w-full">
          <label className="block text-gray-600 mb-2">Change Password</label>
          <input
            type="password"
            className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="New password"
          />
        </div>
  
        <div className="text-right mt-4">
          <button className="px-6 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700">Update Password</button>
        </div>
      </div>
    );
  };
  
  export default AccountSettings;
  