export const DeliveryStatus = Object.freeze({
    PENDING: 'pending',
    SEEN: 'seen',
    PICKED_UP: 'picked_up',
    ON_TRANSIT: 'on_transit',
    DELIVERED: 'delivered',
    CANCELED: 'canceled',
    FAILED: 'failed',
  
    getStatusList: () => {
      return [
        DeliveryStatus.PENDING,
        DeliveryStatus.SEEN,
        DeliveryStatus.PICKED_UP,
        DeliveryStatus.ON_TRANSIT,
        DeliveryStatus.DELIVERED,
        DeliveryStatus.CANCELED,
        DeliveryStatus.FAILED,
      ];
    },
  });
  