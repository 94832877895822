const PrivacySettings = () => {
    return (
      <div className="space-y-4">
        <div className="flex items-center justify-between">
          <span className="text-gray-600">Profile Visibility</span>
          <input type="checkbox" className="toggle toggle-blue" />
        </div>
        <div className="flex items-center justify-between">
          <span className="text-gray-600">Search Engine Visibility</span>
          <input type="checkbox" className="toggle toggle-blue" />
        </div>
        <div className="text-right mt-4">
          <button className="px-6 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700">Save Changes</button>
        </div>
      </div>
    );
  };
  
  export default PrivacySettings;
  