import React from 'react';

const RecentTransactions = () => {
  const transactions = [
    { id: 1, description: 'Payment from John', amount: '$200', date: '2024-11-15' },
    { id: 2, description: 'Refund to Jane', amount: '-$50', date: '2024-11-14' },
    { id: 3, description: 'Payment from Alice', amount: '$300', date: '2024-11-13' },
  ];

  return (
    <div className="bg-white shadow-lg rounded-lg p-6">
      <h2 className="text-xl font-semibold text-gray-700 mb-4">Recent Transactions</h2>
      <ul className="space-y-4">
        {transactions.map((transaction) => (
          <li key={transaction.id} className="flex justify-between text-gray-600">
            <span>{transaction.description}</span>
            <span>{transaction.amount}</span>
            <span>{transaction.date}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default RecentTransactions;
