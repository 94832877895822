import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import { LatLng } from 'leaflet';
import L from 'leaflet';
import customIconUrl from '../assets/images/fromIcon.png';
import DeliveryCodeField from './DeliveryCode';
import { PaymentStatus } from '../enums/paymentStatusEnum';
import { DeliveryStatus } from '../enums/deliveryStatusEnum';
import requestService from '../services/requestService';
import { toast, ToastContainer } from 'react-toastify';

const customIcon = new L.Icon({
    iconUrl: customIconUrl,
    iconSize: [38, 50],
    iconAnchor: [22, 50],
    popupAnchor: [-3, -40],
});

const shadowIcon = L.divIcon({
    className: 'marker-shadow',
    iconSize: [50, 50],
    iconAnchor: [25, 50],
});

const createCardContent = (address, paymentStatus) => {
    return `
    <div class="bg-white p-4 rounded-lg shadow-md border">
      <p class="text-sm text-gray-700"><strong>Address:</strong> ${address}</p>
      <hr class="my-2" />
      <p class="text-sm text-gray-700"><strong>Payment Status:</strong> ${paymentStatus}</p>
    </div>
  `;
};

const RequestDetailsModal = ({ request, onClose }) => {
    const [paymentStatus, setPaymentStatus] = useState(request?.payment_status || PaymentStatus.PENDING);
    const [deliveryStatus, setDeliveryStatus] = useState(request?.delivery_status || DeliveryStatus.PENDING);
    const [loading, setLoading] = useState(false);

    const updateStatus = async () => {
        setLoading(true);
        try {
            const updatedData = await requestService.updateStatus(request.id, {
                payment_status: paymentStatus,
                delivery_status: deliveryStatus,
            });
            toast.success('Status updated successfully!');
        } catch (error) {
            toast.error(`Failed to update status: ${error.message}`);
            console.error('Failed to update status:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        updateStatus();
    }, [paymentStatus, deliveryStatus]);

    if (!request) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">


            <div className="bg-white p-6 rounded-2xl shadow-md max-w-3xl w-full transform transition-all duration-300">
                <h2 className="text-3xl font-semibold text-indigo-600 mb-6">Request Details</h2>

                <div className="max-h-[70vh] overflow-y-auto mb-6">
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 mb-3">
                        <div className="p-4 rounded-lg shadow-sm">
                            <p className="font-medium text-gray-700">Sender</p>
                            <div className="border p-2 rounded-lg">
                                <p className="text-lg text-gray-900">{request.sender_name}</p>
                            </div>
                        </div>
                        <div className="p-4 rounded-lg shadow-sm">
                            <p className="font-medium text-gray-700">Recipient</p>
                            <div className="border p-2 rounded-lg">
                                <p className="text-lg text-gray-900">{request.recipient_name}</p>
                            </div>
                        </div>
                        <div className="p-4 rounded-lg shadow-sm">
                            <p className="font-medium text-gray-700">Item</p>
                            <div className="border p-2 rounded-lg">
                                <p className="text-lg text-gray-900">{request.item_name}</p>
                            </div>
                        </div>

                        <DeliveryCodeField delivery_code={request.delivery_code} />

                        <div className="p-4 rounded-lg shadow-sm">
                            <p className="font-medium text-gray-700">From</p>
                            <div className="border p-2 rounded-lg">
                                <p className="text-lg text-gray-900">{request.from_address}</p>
                            </div>
                        </div>

                        <div className="p-4 rounded-lg shadow-sm">
                            <p className="font-medium text-gray-700">To</p>
                            <div className="border p-2 rounded-lg">
                                <p className="text-lg text-gray-900">{request.to_address}</p>
                            </div>
                        </div>

                        <div className="p-4 rounded-lg shadow-sm">
                            <p className="font-medium text-gray-700">Status</p>
                            <div className="border p-2 rounded-lg">
                                <p className={`text-${deliveryStatus === DeliveryStatus.PENDING ? 'yellow' : 'green'}-600 text-lg font-semibold`}>
                                    {deliveryStatus.toUpperCase()}
                                </p>
                            </div>
                        </div>

                        <div className="p-4 rounded-lg shadow-sm">
                            <p className="font-medium text-gray-700">Payment Status</p>
                            <div className="border p-2 rounded-lg">
                                <p className="text-lg text-gray-900">{paymentStatus.toUpperCase()}</p>
                            </div>
                        </div>

                        <div className="p-4 rounded-lg shadow-sm">
                            <p className="font-medium text-gray-700">Update Payment Status</p>
                            <select
                                value={paymentStatus}
                                onChange={(e) => setPaymentStatus(e.target.value)}
                                className="w-full p-2 border rounded-lg bg-white focus:outline-none focus:ring-2 focus:ring-indigo-500 transition duration-200"
                            >
                                {Object.values(PaymentStatus).map((status) => (
                                    <option key={status} value={status} className="p-2">{status}</option>
                                ))}
                            </select>
                        </div>

                        <div className="p-4 rounded-lg shadow-sm">
                            <p className="font-medium text-gray-700">Update Delivery Status</p>
                            <select
                                value={deliveryStatus}
                                onChange={(e) => setDeliveryStatus(e.target.value)}
                                className="w-full p-2 border rounded-lg bg-white focus:outline-none focus:ring-2 focus:ring-indigo-500 transition duration-200"
                            >
                                {Object.values(DeliveryStatus).map((status) => (
                                    <option key={status} value={status} className="p-2">{status}</option>
                                ))}
                            </select>
                        </div>
                    </div>

                    <hr className="my-4" />

                    <div className="h-72 mb-6 rounded-lg overflow-hidden shadow-lg border">
                        <MapContainer
                            center={new LatLng(request.from_latitude, request.from_longitude)}
                            zoom={13}
                            style={{ height: '100%', width: '100%' }}
                            className="rounded-lg"
                        >
                            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                            <Marker
                                position={new LatLng(request.from_latitude, request.from_longitude)}
                                icon={customIcon}
                            >
                                <Popup>
                                    {createCardContent(request.from_address, request.payment_status)}
                                </Popup>
                            </Marker>

                            <Marker
                                position={new LatLng(request.to_latitude, request.to_longitude)}
                                icon={customIcon}
                            >
                                <Popup>
                                    {createCardContent(request.to_address, request.payment_status)}
                                </Popup>
                            </Marker>
                        </MapContainer>
                    </div>
                </div>

                <button
                    onClick={updateStatus}
                    disabled={loading} // Disable button during loading
                    className="w-full py-2 bg-indigo-600 text-white rounded-lg shadow-md hover:bg-indigo-700 transition duration-300"
                >
                    {loading ? 'Saving...' : 'Save Changes'}
                </button>

                <button
                    onClick={onClose}
                    className="mt-4 w-full py-2 bg-gray-300 text-gray-800 rounded-lg shadow-md hover:bg-gray-400 transition duration-300"
                >
                    Close
                </button>
            </div>
        </div>
    );
};

export default RequestDetailsModal;
